import { toast } from 'react-toastify';
import api from './axiosConfig';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { formatDateBE } from '../utils/datePickerFormat';

export const getAllTransactions = async (
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchBank, searchStatus, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `transaction/merchant/?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchBank && { bank: searchBank }),
        ...(searchStatus && { status: searchStatus }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
          date: {
            from: `${formatDateBE(sDate as string)} 00:00`,
            to: `${formatDateBE(fDate as string)} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getTransactionById = async (id: string) => {
  try {
    const { data } = await api.get(`transaction/merchant`, { params: { id } });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getWithdrawalsHistory = async (itemOffset: number | null, pageSize: number | null) => {
  try {
    const { data } = await api.get(
      `account/merchant/balance/withdrawAll/?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getStatistics = async (filterQueries: {
  sDate: null | Date | string;
  fDate: null | Date | string;
}) => {
  const { sDate, fDate } = filterQueries;
  try {
    const { data } = await api.post(
      `/transaction/merchant/statistics
      `,
      {
        ...{
          date: {
            from: `${sDate} 00:00`,
            to: `${fDate} 23:59`,
          },
        },
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};
export const updateTrans = async (notif_id: number) => {
  try {
    const { data } = await api.post(`transaction/operator/confirm/${notif_id}`);

    return data;
  } catch (err: unknown) {
    toast.error(i18n.t('toast_updateTrans'), {
      position: 'top-right',
    });
  }
};

export const addNewCard = async (
  cardNumber: string | number | readonly string[],
  bankId: number,
  isActive: boolean,
  label: string,
  daily_limit: number,
  month_limit: number,
  tag_id: number | null
) => {
  try {
    const { data } = await api.post(`cards`, {
      card_number: cardNumber,
      bank_id: bankId,
      is_active: isActive,
      tag_id: tag_id,
      label,
      dailyLimit: Number(daily_limit),
      monthly_limit: Number(month_limit),
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 409) {
      toast.error(i18n.t('toast_addCard_conflict'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const withdrawalBalance = async (amount: number) => {
  try {
    const { data } = await api.post(`account/merchant/balance/withdraw`, { amount });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 409) {
      toast.error(i18n.t('toast_withdrawal_validate'), {
        position: 'top-right',
      });
    } else if (error.response?.status === 406) {
      toast.error(
        i18n.t('forbidden_recent_sums_withdrawal', { hoursThreshold: 12, position: 'top-right' })
      );
    }
    console.log(error.message);
  }
};

export const getCourses = async (token: string | null) => {
  try {
    const { data } = await api.get('course/fetchData/merchant', {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
};

export const getAllFiat = async (token: string | null) => {
  try {
    const { data } = await api.get('course/all/fiat/merchant', {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
};

export const getAllQACTransactions = async (
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchBank, searchStatus, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `/transaction-acq/merchant/getAll/acq/?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchBank && { bank: searchBank }),
        ...(searchStatus && { status: searchStatus }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
          date: {
            from: `${formatDateBE(sDate as string)} 00:00`,
            to: `${formatDateBE(fDate as string)} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getAllPayouts = async (
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchStatus, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `/payout/merchant/getAll/?page=${(itemOffset && itemOffset) || ''}&page_size=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchStatus && { status: searchStatus }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
            date: {
              from: `${formatDateBE(sDate as string)} 00:00`,
              to: `${formatDateBE(fDate as string)} 23:59`,
            },
          }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};


export const getQacTransactionById = async (id: number) => {
  try {
    const { data } = await api.get(`/transaction-acq/merchant/${id}`);
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getPayoutById = async (id: number) => {
  try {
    const { data } = await api.get(`/payout/merchant/${id}`);
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const downloadHistory = async (filterQueries: {
  searchQuery: string;
  searchBank: string | null;
  searchStatus: string | null;
  sDate: null | Date | string;
  fDate: null | Date | string;
  sort: null | string; }, token: string | null) => {
  try {
    const filterData = {
      ...(filterQueries.searchQuery !== "" && { label: filterQueries.searchQuery }),
      ...(filterQueries.searchBank !== "" && { bank: filterQueries.searchBank }),
      ...(filterQueries.searchStatus !== null && { status: filterQueries.searchStatus }),
      ...(filterQueries.sDate !== null && { date: { from: filterQueries.sDate } }),
      ...(filterQueries.fDate !== null && { date: { from: filterQueries.sDate, to: filterQueries.fDate } }),
    };

    const response = await api.post('transaction/merchant/getData/csv', filterData, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const blobData = response.data;
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transaction_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error', error);
  }
};

export const downloadPayoutHistory = async (filterQueries: {
  searchQuery: string;
  searchStatus: string | null;
  sDate: null | Date | string;
  fDate: null | Date | string;
  sort: null | string; }, token: string | null) => {
  try {
    const filterData = {
      ...(filterQueries.searchQuery !== "" && { label: filterQueries.searchQuery }),
      ...(filterQueries.searchStatus !== null && { status: filterQueries.searchStatus }),
      ...(filterQueries.sDate !== null && { date: { from: filterQueries.sDate } }),
      ...(filterQueries.fDate !== null && { date: { from: filterQueries.sDate, to: filterQueries.fDate } }),
    };

    const response = await api.post('payout/merchant/history/csv', filterData, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const blobData = response.data;
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'payout_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error: any) {
    console.log(error);
    toast.error(i18n.t(error.response.message), {
      position: 'top-right',
    });
  }
};

export const downloadHistoryQAC = async (filterQueries: {
  searchQuery: string;
  searchBank: string | null;
  searchStatus: string | null;
  sDate: null | Date | string;
  fDate: null | Date | string;
  sort: null | string;
}, token: string | null) => {
  try {
    const filterData = {
      ...(filterQueries.searchQuery !== "" && { label: filterQueries.searchQuery }),
      ...(filterQueries.searchBank !== "" && { bank: filterQueries.searchBank }),
      ...(filterQueries.searchStatus !== null && { status: filterQueries.searchStatus }),
      ...(filterQueries.sDate !== null && { date: { from: filterQueries.sDate } }),
      ...(filterQueries.fDate !== null && { date: { from: filterQueries.sDate, to: filterQueries.fDate } }),
    };

    const response = await api.post('transaction-acq/merchant/getData/csv', filterData, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const blobData = response.data;
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transaction_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error', error);
  }
};

export const findTransactionById = async ( transaction_id: number | string) => {
  try {
    const { data } = await api.post('transaction/merchant', { transaction_id });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
};

export const getAllStatistics = async (filterQueries: {
  sDate: null | Date | string;
  fDate: null | Date | string;
  currency: null | string;
}) => {
  const { sDate, fDate, currency } = filterQueries;
  try {
    const { data } = await api.post(
      `/account/merchant/getStatistics/all`,
      {
        ...{
          date: {
            from: `${sDate} 00:00`,
            to: `${fDate} 23:59`,
          },
          currency
        },
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getOtherStatistics = async (filterQueries: {
  sDate: null | Date | string;
  fDate: null | Date | string;
  currency: null | string;
}) => {
  const { sDate, fDate, currency } = filterQueries;
  try {
    const { data } = await api.post(
      `/transaction/merchant/general/statistics`,

      {
        ...{
          from: `${sDate} 00:00`,
          to: `${fDate} 23:59`,
        },
        currency
      },
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getConstants = async () => {
  try {
    const { data } = await api.get('account/merchant/settings');
    return data;
  }
  catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
}

